import { ConfigMurucaResourceLayout } from '@net7/boilerplate-muruca';

export default {
  maxHeight: 100, // Threshold where the "read-more" button appears
  title: 'Traduccion',
  type: 'traduccion',
  sections: {
    top: [
      {
        id: 'header',
        type: 'title',
        grid: null,
      },
    ],
    content: [
      {
        id: 'metadata',
        type: 'metadata',
        title: 'resource#metadata',
      },
      {
        id: 'collection-referencia',
        type: 'collection',
        grid: 1,
        title: 'resource#obradereferencia',
      },
      {
        id: 'collection-bibliography',
        type: 'collection',
        grid: 1,
        title: 'Bibliografia',
        options: {
          classes: 'mr-item-preview-bibliography',
          itemPreview: {
            limit: 9999,
            striptags: false,
          },
        },
      },
    ],
  },
} as ConfigMurucaResourceLayout;
